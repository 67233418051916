.wdk-demo-modal .modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;
}

.modal-open .kss-documentation {
  z-index: auto;
}
